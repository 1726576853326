import React from "react";
import ProductsContainer from "../components/containers/products-container";
import { Row, Col } from "react-bootstrap";

import { Seo } from "../components/helpers/seo";
const CategoryTemplate = ({ pageContext }) => {
  const { category } = pageContext;
  const desc = { __html: category.descriptionHtml };
  return (
    <>
      <Seo title={category.title} />
      <Row>
        <Col>
          <h1
            style={{
              paddingTop: "15px"
            }}
          >
            {category.title}
          </h1>
          <div dangerouslySetInnerHTML={desc} />
          <hr />
          <ProductsContainer
            products={category.products}
            filters={{ category: category.handle, querystring: "" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default CategoryTemplate;
